import imagesLoaded from 'imagesloaded';
import Swiper from 'swiper';
import swiper from 'swiper/bundle';
import 'swiper/css/bundle';
//import 'swiper/swiper-bundle.css';
import easing from 'jquery.easing';
import selectric from 'selectric';
import 'selectric/public/selectric.css';
import lightcase from "lightcase";
import 'lightcase/src/css/lightcase.css';


export class App {
  constructor(){
    this.win = window;
    this.body = document.body;
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    this.wrap = document.getElementById('page');
    this.sideNav = document.getElementById('sideNav');
    this.sideNav_inner = this.sideNav.querySelector('.inner');
    this.gNav = document.getElementById('site-navigation');
    this.heroContainer = document.getElementById('heroContainer');
    this.loaded_cnt = 0;
    this.loaded = false;
    this.max_loaded = 10;
    this.nav_judge = false;
    this.sc_judge = false;
    this.maxW = 896;
    this.mobile = document.body.classList.contains('mobile')? true : false;
    this.sc_judge = false;
    this.pageName="";

  }
  init(){
    this.btn_hum_container = document.createElement('div');
    this.btn_hum_container.innerHTML = "<span class='txt'>MENU</span>";
    this.btn_hum = document.createElement('span');
    this.btn_hum.classList.add('btn_hum');
    this.btn_hum.innerHTML = "<span class='bar'></span><span class='bar'></span><span class='bar'></span>";
    this.btn_hum_container.insertBefore(this.btn_hum,this.btn_hum_container.firstElementChild);
    this.btn_hum_container.classList.add('btnHumContainer');
    this.sideNav_inner.insertBefore(this.btn_hum_container, this.sideNav_inner.firstElementChild);

    const menu_container = this.gNav.querySelector('.menu-container');
    this.gNav_back = document.createElement('span');
    this.gNav_back.classList.add('gnav_back');
    menu_container.appendChild(this.gNav_back);

    this.mvWrapper = document.getElementById('mvWrapper');
    this.yt=false;
    if(this.mvWrapper !== null){
      this.yt = true;
      this.ratio = this.winH / this.winW;

    }

    if(this.body.classList.contains('home')){
      this.pageName = "home";
      //this.btn_bnr = document.getElementById('btn_bnr');
      this.secConcept = document.getElementById('secConcept');
      this.secWrap = document.getElementById('secWrap');
    }

    //if(this.body.classList.contains('work') || this.body.classList.contains('eat')){
    if(document.getElementById('pageNavContainer') != null){
      this.btn_cl = document.getElementById('btn_cl');
      this.pupWrap = document.getElementById('pupWrap');
      this.pupBack = document.getElementById('pupBack');
      this.imgHaus = document.getElementById('imgHaus');
      this.pup_judge = false;
      const pageNavContainer = document.getElementById('pageNavContainer');
      const ttls = document.querySelectorAll('.sec-header .ttl:not(.notList)');
      //console.log(ttls);
      // const ttls2 = [].slice.call(document.querySelectorAll('.sec-header .ttl'));
      // console.log(ttls2);
      if(ttls){
        const pageNavUl = document.createElement('ul');
        pageNavUl.classList.add('page_nav-list');
        let items="";
        let _ttl;
        for(const ttl of ttls){
          if(ttl.hasChildNodes()){
            if(ttl.firstElementChild != null){
              if(ttl.firstElementChild.tagName == "IMG"){
                _ttl=ttl.firstElementChild.getAttribute('alt');
              }
            }else{
              if(ttl.childNodes[0].nodeType == '3'){
                _ttl=ttl.textContent;
              }
            }
            //console.log(_ttl);
            // for(const _child of ttl.childNodes){
            //   _ttl = "";
            //   if(_child.nodeType == 3){
            //     _ttl = ttl.textContent;
            //   }else if(_child.nodeType == 1){
            //     _ttl = _child.getAttribute('alt');
            //     //console.log(_ttl);
            //   }else{
            //     //_ttl = "";
            //   }
            //   //console.log(_ttl);
            //   console.log(_ttl, _child.nodeType, _child.tagName);
            // }
            //_ttl = ttl.textContent;
          // }
          // if(_ttl != null || _ttl != ""){
            const _href = ttl.closest('.sec').getAttribute('id');
            items += `<li class="page_nav-item"><a href="#${_href}">${_ttl}</a></li>`;
          }
        }
        pageNavUl.innerHTML = items;
        pageNavContainer.appendChild(pageNavUl);
      }

    }

    if(this.body.classList.contains('work')){
      this.planContainer = document.getElementById('planContainer');
      this.planBtnContainer = document.createElement('div');
      this.planBtnContainer.classList.add('plan_btn_container');
      const plan_ttls = this.planContainer.querySelectorAll('.plan-title');
      for(let ttl of plan_ttls){
        let arrow = document.createElement('div');
        arrow.classList.add('btn_dn');
        ttl.appendChild(arrow);
        this.planBtnContainer.appendChild(ttl);
      }
      this.planContainer.parentNode.insertBefore(this.planBtnContainer, this.planContainer);

      this.arrows = document.querySelectorAll('.arrow');
      const ajax_url = home_url+"/wp/wp-admin/admin-ajax.php";
      //console.log(ajax_url);

      //　add 2023/01/11
      if(document.getElementById('res') != null){
      $.ajax({
        type: 'POST',
        url: ajax_url,
        data: {
          'action':'my_ajax',
        },
        success: (responce) => {
          // console.log(responce);
          document.getElementById('res').innerHTML = responce;

          $('a[data-rel^=lightcase]').lightcase({
            maxHeight:2000,
            maxWidth:2000
          });
          
        }
      });
    }

      

      
     
    }

    this.btn_bnr = document.getElementById('btn_bnr');
    this.secNews = document.getElementById('secNews');

    // console.log(this.btn_bnr);
    // console.log(this.secNews);
    if(this.btn_bnr != null){
      this.btn_jud = true;
      const _pa = this.btn_bnr.closest('.sec');
      _pa.style.position="relative";
    }else{
      this.btn_jud = false;
    }

    //console.log('app init');
    //time
    const zero = n => (n < 10)? "0"+n.toString() : n.toString();
    const lang = navigator.language;
    const clock = document.getElementById('clock');
    //console.log(lang);
    const getHourString = date => `JP ${zero(date.getHours())}:${zero(date.getMinutes())}`;

    editTime();

    window.addEventListener('DOMContentLoaded',()=>{

      setInterval(()=>{
        editTime();
      },15000);
    });
    function editTime(){
      const now = new Date();
      //console.log(now);
      clock.innerText = getHourString(now);
    }


    const _this = this;
    this.imgLoaded = imagesLoaded(this.wrap);
    this.imgLoaded.on('always',load_always);
    this.imgLoaded.on('progress',load_progress);

    function load_always(instance){
      if(!_this.loaded){
        //console.log('always');
        loaded_event();
      }
    }

    function load_progress(instance, image){
      _this.loaded_cnt++;
      if(_this.loaded_cnt > _this.max_loaded){
        //console.log('progress');
        loaded_event();
      }
    }
    function loaded_event(){
      //console.log('loaded');
      _this.loaded = true;
      _this.imgLoaded.off('progress',load_progress);
      _this.imgLoaded.off('always',load_always);
      _this.wrap.classList.add('on');
      $('.spinner').fadeOut('slow',function(){
        $(this).remove();
        setTimeout(function(){
          _this.body.classList.add('loaded');
        },500);
      });
      _this.events();
      _this.anchorLink();
    }

    this.swObj = {
      loop:true,
      speed:1000,
      autoplay:{
        delay:4000,
        disableOnInteraction:false
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
    },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        slideChange: (e)=>{
          //console.log('change');
        }
      },
      observer: true,
      observeParents: true
    }
    //console.log(this.swObj);
    //this.top_swiper = new Swiper('.swiper-container',this.swObj);

    this.sliders = document.querySelectorAll('.swiper-container');
    this.slider_arr = [];
    let sl_cnt = 1;
    for(let slider of this.sliders){
      slider.classList.add('sl'+sl_cnt);
      this.slider_arr.push(slider);
      sl_cnt++;
    }

    let sl_cnt2 = 1;
    for(let sl of this.sliders){
      if(sl.querySelectorAll('.swiper-slide').length > 1){
        let sw = new Swiper('.sl'+sl_cnt2,this.swObj);
      }
      sl_cnt2++;
    }
    // console.log(this.sliders);

  }
  events(){
    this.btn_hum.addEventListener('click',(e)=>{
      if(!this.nav_judge){
        this.nav_judge = true;
        e.currentTarget.classList.add('on');
        this.gNav.classList.add('on');
        document.documentElement.style.overflow = 'hidden';
        document.addEventListener('touchmove',scrollControll,{passive:false})
      }else{
        this.nav_judge = false;
        e.currentTarget.classList.remove('on');
        this.gNav.classList.remove('on');
        document.documentElement.style.overflow = '';
        document.removeEventListener('touchmove',scrollControll,{passive:false})
      }
    });
    this.gNav_back.addEventListener('click',(e)=>{
      if(this.nav_judge){
        this.nav_judge = false;
        this.btn_hum.classList.remove('on');
        this.gNav.classList.remove('on');
        document.documentElement.style.overflow = '';
        document.removeEventListener('touchmove',scrollControll,{passive:false})
      }
    });

    function scrollControll(e){
        if($(e.target).closest('.spNav-listContainer').length > 0){
          e.stopPropagation();
        }else{
          e.preventDefault();
        }
      }

      if(this.body.classList.contains('work') ||
    this.body.classList.contains('eat')){
      const _this = this;
      if(this.planBtnContainer){
      this.btn_plans = this.planBtnContainer.querySelectorAll('.plan-title');
      this.plan_list = this.planContainer.querySelectorAll('.plan-content');
      //console.log(this.btn_plans);
      for(let btn of this.btn_plans){
        btn.addEventListener('click',(e)=>{
          if(!btn.classList.contains('active')){
            clearClass();
            e.currentTarget.classList.add('active');
            let index = [].slice.call(this.btn_plans).indexOf(btn);
            this.plan_list[index].classList.add('active');
          }
        });
      }
      function clearClass(){
        for(let bt of _this.btn_plans){
          bt.classList.remove('active');
        }
        for(let plan of _this.plan_list){
          plan.classList.remove('active');
        }
      }

      this.arrows.forEach((ar)=>{
        ar.addEventListener('click',(e)=>{
          if(ar.classList.contains('on')){
            ar.classList.remove('on');
          }else{
            ar.classList.add('on');
          }
          $(ar.nextElementSibling).slideToggle('slow');
        });


      });

    }
    if(this.imgHaus !== null) {
    this.imgHaus.addEventListener('click',(e)=>{
      console.log("click");
      if(!this.pup_judge){
        this.pup_judge = true;
        this.pupWrap.classList.add('on');
        document.documentElement.style.overflow = 'hidden';
        //document.addEventListener('touchmove',zoningSc,{passive:false});
      }
    });
  }

    this.btn_cl.addEventListener('click',(e)=>{
      if(this.pup_judge){
        this.pup_judge = false;
        this.pupWrap.classList.remove('on');
        document.documentElement.style.overflow = '';
        //document.removeEventListener('touchmove',zoningSc,{passive:false});
      }
    });

    this.pupBack.addEventListener('click',(e)=>{
      if(this.pup_judge){
        this.pup_judge = false;
        this.pupWrap.classList.remove('on');
        document.documentElement.style.overflow = '';
        //document.removeEventListener('touchmove',zoningSc,{passive:false});
      }
    });


    function zoningSc(e){
      e.preventDefault();
    }

    }//works

    // const observer = new IntersectionObserver((entries) => {
    //   entries.forEach((entry)=> console.log(entry));
    // });
    //
    // observer.observe(document.getElementById('secNews'));

    window.addEventListener('scroll',(e)=>{
      this.scroll();
    });

    // if(this.body.classList.contains('home')){
    //
    // }//home

    window.addEventListener('resize',(e)=>{
      this.resize();
    });
    window.matchMedia("(max-width:"+this.maxW+"px)").addListener(() => {
      //console.log('match');
      if(window.matchMedia("(min-width:"+(this.maxW-1)+"px)").matches){
        // this.btn_menu_sp.classList.remove('on');
        // this.gnav_sp.classList.remove('on');
      }
    });

    const resize_observer = new ResizeObserver((entries)=>{
      //console.log(`res : ${entries[0]}`);
      this.heroContainer.style.height=this.winH+"px";
    });

    const mediaQueryList = window.matchMedia("(max-width:"+this.maxW+"px)");
    const listener = (e)=>{
      if(e.matches){
        this.winSize = 'sp';
        //resize_observer.observe(this.heroContainer);
      }else{
        this.winSize = 'pc';
        // this.heroContainer.style.height="";
        // resize_observer.disconnect();
        //this.gNav.classList.remove('on');
        this.sc_judge = false;
      }
      //console.log(this.winSize);
    }
    mediaQueryList.addListener(listener);
    listener(mediaQueryList);





    if(this.body.classList.contains('page') && this.body.classList.contains('news')){
      this.sels = document.querySelectorAll('select');
      this.sel_arr = [];
      for(const sel of this.sels){
        this.sel_arr.push($(sel).selectric());
      }

      const btnClear = document.getElementById('btnClear');
      const ops = document.querySelectorAll('.filterList select option');

      //console.log(sel_arr);
      btnClear.addEventListener('click',()=>{
        console.log('click');
        for(const op of ops){
          // sel.selectedIndex = -1;
          $(op).prop('selected',false);
          $(op).attr('selected',false);
          //console.log(sel);
        }
        for(const _sel of this.sel_arr){
          //console.log(_sel);
          _sel.selectric('refresh');
        }
      });
    }
    this.scroll();
    this.resize();

  }//events

  anchorLink(){
    const _atags = document.querySelectorAll("a[href^='#']");
    const sp = 900;
    const ease = "easeInOutExpo";

    for(let _a of _atags){
      _a.addEventListener('click',(e)=>{
        e.preventDefault();

        let href = e.currentTarget.getAttribute('href');
        href = (href == "#" || href == "")? "html" : href;
        href = href.replace("#","");
        let pos_top = 0;

        if(href !== "html"){
          let target = document.getElementById(href);
          let rect = target.getBoundingClientRect();
          pos_top = rect.top;
          // console.log(target);
          // console.log(rect);
          // console.log(pos_top);
        }

        let _that = e.currentTarget;
        let _top = window.pageYOffset + pos_top;

        if(href == "html"){
          pos_top = 0;
        }
        $('html,body').animate({
          scrollTop:_top
        },sp,ease);

      });
    }
  }

  scroll(){
    //if(this.pageName == "home"){
    if(this.btn_jud){
      this.winscTop = window.pageYOffset;
      this.secNewsRect = this.secNews.getBoundingClientRect();
      if(this.secNewsRect.top < 0){
        if(!this.sc_judge){
          this.sc_judge = true;
          //console.log('sc true');
          this.btn_bnr.classList.add('on');
          //this.gNav.classList.add('on');
        }
      }else{
        if(this.sc_judge){
          //console.log('sc false');
          this.sc_judge = false;
          this.btn_bnr.classList.remove('on');
          //this.gNav.classList.remove('on');
        }
      }
    }
  }

  resize(){
    //console.log('res');
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    if(this.winSize == 'sp'){
      if(this.heroContainer !== null){
        this.heroContainer.style.height=this.winH+"px";
      }
    }
    if(this.yt){
      this.ratio = this.winH / this.winW;
      if(this.ratio >= 0.5625){
        //console.log(this.mvWrapper);
        this.mvWrapper.style.width = this.winH * 1.77 + "px";
      }else{
        this.mvWrapper.style.width = "";
      }
    }
    //this.headerH = this.header.offsetHeight;

  }

    // aos.init({
    //   delay:400,
    //   duration:1000,
    //   once:true
    // });

}
